<template>
  <div>
    <div class="tit">
      <div style="font-size:18px;">加入我们</div>
    </div>
    <div class="select">
      <a-select
        default-value="数据加工员/档案电子信息化"
        style="width: 60%;top: 15px;left: 20px"
        @change="handleChange"
      >
        <a-select-option value="staff">
          数据加工员/档案电子信息化
        </a-select-option>
        <a-select-option value="market"> 销售代表 </a-select-option>
      </a-select>
    </div>
    <!-- 数据加工员 -->
    <div class="usCont" v-show="show === 'staff'">
      <div class="skill">
        <div class="skillTit">技能、经验要求</div>
        <ul>
          <li>1、打字速度快，熟练五笔优先考虑；</li>
          <li>2、熟悉1种以上图片处理软件；</li>
          <li>
            3、身体健康，工作细致，责任心强，喜欢从事档案加工类工作；
          </li>
          <li>4、计算机相关专业或有相关工作经验者优先。</li>
        </ul>
      </div>
      <div class="skill">
        <div class="skillTit">任职要求</div>
        <ul>
          <li>1、大学专科或以上学历，专业不限，男女不限；</li>
          <li>2、年龄18-35周岁；</li>
          <li>3、具备良好的学习能力、沟通能力。</li>
        </ul>
      </div>
      <div class="but">联系电话：0736—6663331转8007</div>
    </div>
    <!-- 销售 -->
    <div class="usCont" v-show="show === 'market'">
      <div class="skill">
        <div class="skillTit">技能、经验要求</div>
        <ul>
          <li>1、热爱销售工作；</li>
          <li>2、具有良好的学习能力、沟通能力、公关能力；</li>
          <li>
            3、具有政府行业销售经验或客户资源优先录取。
          </li>
        </ul>
      </div>
      <div class="skill">
        <div class="skillTit">任职要求</div>
        <ul>
          <li>1、学历不限，男女不限；</li>
          <li>2、年龄20-35周岁。</li>
        </ul>
      </div>
      <div class="but">联系电话：0736—6663331转8007</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: "staff",
      
    };
  },
  methods: {

    handleChange(value) {
      if (value == "market") {
        this.show = "market";
      }else{
        this.show = "staff";
      }
    },
  },
};
</script>