<template>
    <div>
        <div class="tit">
            <div style="font-size: 18px">产教融合</div>
        </div>
        <div class="content">
            <h1>产教融合三年规划</h1>
            <h2>一、企业概况</h2>
            <p>湖南新讯信息科技有限公司成立于 2017 年，是一家集研发、生产、销售于一体的大数据企业。公司位于湖南省桃源县高新技术产业开发区，占地面积 50 余亩，园区建设包括基础数据处理服务中心、数据应用研发服务中心、业务场景综合应用中心、智慧云服务中心，致力为客户提供专业的数据处理、技术开发及数据解决方案。企业连续多年无重大环保、安全、质量事故，具有良好信用记录，无涉税等违法违规经营行为。自 2019 年以来，公司与东莞理工学院粤台产业科技学院建立了长期全面的产学研合作关系，共建了校企产教融合基地。近三年接受该学院学生开展每年 3个月以上实习实训累计超过 115 人，通过双方合作取得了共享知识产权 5个。</p>
            <h2>二、合作目的与领域</h2>
            <h3>（一）合作目的</h3>
            <p>深入贯彻党的十九大和全国教育大会精神，深化产教融合，激发企业参与校企合作的积极性，促进企业深度参与产教融合、校企合作，推进产教协同育人，拓宽企业参与人才培养的渠道和途径，校企双方联合举办职业教育、建设实训基地、组建产教联盟和职教集团、开展产学研合作等，进一步发挥企业重要主体作用，提升技术技能人才培养质量，增强吸引力和竞争力，建设粤北地区具有较强带动引领示范效应的企业。</p>
            <h3>（二）合作领域</h3>
            <p>在三年建设期内，我司将进一步巩固以往产教融合所取得的成果，坚持校企合作方针，依托东莞理工学院电子商务专业群的资源，创新“合作办学、协同育人、合作就业、合作发展”的紧密型产教融合机制与体制，充分利用各种资源，建立企校产教融合体制机制，企校协同对接职业技术标准，建设与现代企业生产技术同步的教学资源，打造具有各项专家的师资团队，建设企校合作产教融合共享型实训平台，着力解决制约校企合作中的各种困难和问题，积极探索校企深度融合的办学模式，努力实现校企人才共享、设备共享、技术共享、校企文化互补、校企管理互通的深度校企合作关系，全面提升学校办学能力和企业的市场竞争力、社会影响力。</p>
            <h2>三、主要目标</h2>
            <p>（一）通过校企合作中的科研课题项目合作、学术交流平台、校企互聘、同教同学等形式，拓宽企业各类人才的视野，促进企业自我研发能力的提高，从而全面提升企业整体市场竞争力。</p>
            <p>（二）通过校企合作中的“成人教育”“开放教育”等模式，不断提高企业管理人员和技术骨干的理论知识、专业素养和学历水平，计划每年通过学校培训3~5名企业管理人员或技术骨干。</p>
            <p>（三）建立企校合作体制机制，开展订单培养。建立企业与学院合作体制机制，招收“学徒”，企业技术人才、能工巧匠全程参与职业院校专业人才培养方案的制订。培养一批品德高尚、技能精湛、企业急需专业技能型人才。深化企校合作、产教融合。企校共同探索产教融合机制创新，协调开展学生的职业素养教育，将公司先进产业文化、企业文化融入专业教育。通过订单培养、现代学徒制培养，建立“企校利益共同体”，将企校融合、具有企业特色的文化建设落实到人才培养方案中，融入人才培养的全过程，通过专业课程设置将产业文化、企业文化渗透教学中，培养学生职业素养，有效地提高学生成为公司员工后的综合素质和能力。</p>
            <h2>四、保障措施</h2>
            <h3>（一）争取政策支持</h3>
            <p>在现有一系列政策基础上，积极争取政府制定相关政策支持，建立企业与学院共赢长效机制，合力构建产教融合、校企合作平台。</p>
            <h3>（二）组织保障</h3>
            <p><strong>1. 校企双方共同成立产教融合、校企合作领导小组。</strong>由主要负责同志任领导小组正、副组长，各对应部门负责人为工作组成员。领导小组下设办公室，办公室负责规划编制、立项、实施和协调管理等工作，确保规划目标和责任的顺利落实。</p>
            <p><strong>2.明确领导小组职责。</strong>领导小组主要职责是研究企校合作建设的总体思路和重大项目；负责合作建设的统筹规划，提供资金及有关政策的保障；保证实施方案落实到位。</p>
            <p>产教融合、校企合作领导小组分设专业教学和实训指导小组，主要是明确专业人员的培养目标，确定专业教学计划的方案，提供市场人才需求信息，参与学校教学计划的制定和调整，根据公司、行业的用工要求及时调整学校的专业计划和实训计划，协助学校建立和管理校内外实训基地。</p>
            <p><strong>3.加强协调。</strong>加强企校工作的协调沟通，学校派专人到公司进行学生日常管理，公司各部门根据工作要求对实习和配置学生进行工作指导。</p>
            <h3>（三）资金保障</h3>
            <p>项目建设资金实行报账制管理，专款专用。严格按照省批复的项目设计方案运行，加强资金审计监督，严禁项目资金截留挪用，确保资金安全运行。企业自筹资金投资完成基地所有建筑用房、道路等基础设施。</p>
            <h3>（四）质量监控</h3>
            <p>依据全面质量管理理念，实行多目标管理，以考核性诊断为主线，以标准与制度体系建设为基础，利用企校合作过程和结果为依据，形成质量保证的管理机制，培育以自律、职业精神的质量文化。</p>
            <p>以产教融合、校企合作三年规划为依据制订一系列目标，结合高职教育人才培养内在规律、建立人才培养的质量目标和质量标准，建立质量保证体系，完善企校合作的质量管理流程，形成常态化、可考核、激励明显的质量保证体系，实现人才培养质量和校企共同发展持续提升。</p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    .content {
        padding:0 20px;
    }
    h1 {
        font-size:18px;
        font-weight: bold;
        text-align: center;
        padding:15px 0;
    }
    h2 {
        font-size:16px;
        font-weight: bold;
    }
    h3 {
        font-size:15px;
        font-weight: bold;
    }
    p {
        text-indent: 28px;
        font-size:15px;
        color:#666;
        line-height: 32px;
    }
</style>